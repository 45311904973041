
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
  watchEffect,
} from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiService from "@/core/services/ApiService";
import { translate } from "@/core/services/HelpersFun";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { platforms } from "@/store/pinia/Platforms";
import { AllBots } from "@/store/pinia/AllBots";
import { storeToRefs } from "pinia";
import axios from "axios";
// import Map from "../components/Map.vue";
import apiService from "@/core/services/ApiService";
// import item1 from "@/layout/header/partials/activity-timeline/Item1.vue";
// import { target } from "nouislider";
// import Platform from "@/views/pages/robots/component/Platform.vue";

interface NewAddressData {
  type: string;
  bots: Array<string | number>;
  start_crawling_date: string;
  start_time: string;
  numbers_count: number;
  categories: Array<any>;
  categoriesFilters: Array<any>;
  cities: any;
  citiesFilters: any;
  disabled: boolean;
  filters: any;
  activeFilterCityId: string | number;
  activeFilterSearchId: string | number;
  remoteSearchHolder: any;
}

interface Tree {
  id: string;
  label: string;
  children?: Tree[];
}

export default defineComponent({
  name: "create-request-form",
  components: {
    // Map,
  },
  emit: ["refreshList"],
  props: {
    botId: {
      type: String,
      default: "",
    },
  },
  setup: function (props, { emit }) {
    const labelPosition = ref();
    const formRef = ref<null | HTMLFormElement>(null);
    const newTargetModalRef = ref<null | HTMLElement>(null);

    const loading = ref<boolean>(false);
    const store = useStore();

    const treeSelected = ref();
    const treeSelectedStates = ref();

    const targetData = reactive<NewAddressData>({
      type: "bama",
      bots: props.botId ? [`${props.botId}`] : [],
      start_crawling_date: "",
      start_time: "",
      numbers_count: 50,
      categories: [],
      categoriesFilters: [],
      cities: [],
      citiesFilters: [],
      disabled: false,
      filters: [
        {
          type: "search_term",
          value: "",
          display_name: "جستجو",
        },
      ],
      activeFilterCityId: "",
      activeFilterSearchId: "",
      remoteSearchHolder: [],
    });

    const AllBotsHolder = AllBots();
    const { crowlerBotList } = storeToRefs(AllBotsHolder);

    const platformsHolder = platforms();
    const { crowlerPlatformsList } = storeToRefs(platformsHolder);

    const targetPlatform = computed(() => {
      if (
        !crowlerPlatformsList.value ||
        typeof crowlerPlatformsList.value !== "object"
      )
        return null;
      return (
        Object.values(crowlerPlatformsList.value).find(
          (item: any) => item["type"] == targetData.type
        ) || null
      );
    });

    const rules = ref({
      type: [
        {
          required: true,
          message: "لطفا سایت مقصد را انتخاب کنید.",
          trigger: "change",
        },
      ],
      bots: [
        {
          required: true,
          message:
            "لطفا اشتراک یا اشتراک هایی که میخواهید استخراج را انجام دهد را انتخاب کنید.",
          trigger: "change",
        },
      ],
      start_crawling_date: [
        {
          required: true,
          message: "تاریخ شروع را انتخاب کنید",
          trigger: "change",
        },
      ],
      start_time: [
        {
          required: true,
          message: "ساعت شروع را انتخاب کنید",
          trigger: "change",
        },
      ],
      numbers_count: [
        {
          required: true,
          message: "تعداد شماره های مورد نیاز",
          trigger: "blur",
        },
      ],
    });

    const bots = computed(() => {
      return crowlerBotList.value;
    });

    const types = computed(() => {
      return crowlerPlatformsList.value;
    });

    const categories = ref();
    const getCategories = async () => {
      await ApiService.get(`/crawler/categories/${targetData.type}/tree`).then(
        ({ data }) => {
          console.log(data.data);
          categories.value = data.data.categories_tree;
        }
      );
    };

    const cities = ref();
    const getCities = async () => {
      await ApiService.get(`/crawler/cities/${targetData.type}`).then(
        ({ data }) => {
          cities.value = data.data.states;
        }
      );
    };

    const filterBotTypes = (type) => {
      if (!bots.value) return [];
      return bots.value.filter((item) => {
        if (Object.keys(item.platforms).length > 0) {
          let plat = item.platforms.filter((plat) => plat.platform === type);
          if (plat.length > 0) return item;
        }
      });
    };

    const handleFetchData = async () => {
      targetData.disabled = true;
      await getCities();
      await getCategories();
      targetData.disabled = false;
    };

    watch(
      () => targetData.type,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          handleFetchData();
        }
      }
    );

    // Tree
    const propsCat = {
      value: "id",
      label: "name",
      children: "children",
      source_id: "source_id",
    };

    const propsState = {
      value: "id",
      source_id: "source_id",
      label: "name",
      children: "cites",
    };

    // start filter categoery
    // وقتی که کاربر دسته بندی را انتخاب میکند ازین طریق فیلتر های دسته بندی فعال میشه و کاربر میتونه فیتر ها را انجام بده
    // پیدا کردن آبجکت دسته بندی بر اساس آی دی
    const findMatchingItemsInCategories = (obj, stack) => {
      console.log(111111111);

      if (typeof obj !== "object" || obj === null) return;
      if (Array.isArray(obj)) {
        obj.forEach((item) => {
          if (
            item.id &&
            targetData.categories.includes(item.id) &&
            item.filters &&
            item.filters.length > 0
          ) {
            item.filters.forEach((filterItem) => {
              targetData.categoriesFilters.push({
                type: "filter",
                city_id: null,
                category_id: item.source_id,
                value: "", // if filterType === list then value is list.key else if boolean then value is true | false
                filterType: filterItem.type, // list | boolean | search
                filter_id: filterItem.id,
                filterData: filterItem,
                display_name: item.name,
                id: item.id, // این آی دی دسته بندی می باشد که از آن برای مرتب‌سازی و هم چنین حذف موارد تکراری استفاده می کنم.
              });
            });
          }
          if (
            typeof item.children === "object" &&
            item !== null &&
            Object.keys(item).length > 0
          ) {
            console.log(2222222);

            findMatchingItemsInCategories(item.children, targetData.categories);
          }
        });
      } else if (typeof obj === "object") {
        console.log(3333333333);

        Object.values(obj).forEach((value) => {
          findMatchingItemsInCategories(value, targetData.categories);
        });
      }
    };

    // یافتن آبجکت دسته بندی
    watch(
      () => targetData.categories,
      () => {
        findMatchingItemsInCategories(categories.value, targetData.categories);
      }
    );

    // end filter category
    const getStateCheckedData = computed(() => {
      // if (!treeSelectedStates.value) return [];
      // return treeSelectedStates.value!.getCheckedNodes(false);
      return [] as any;
    });

    // start filter cities
    const findMatchingItemsInCities = (obj, stack) => {
      if (typeof obj !== "object" || obj === null) return;
      if (Array.isArray(obj)) {
        obj.forEach((item) => {
          let checkExist = targetData.remoteSearchHolder.find(
            (find) => find["city_id"] === item.id
          );
          if (
            item.id &&
            targetData.cities.includes(item.id) &&
            checkExist === undefined
          ) {
            targetData.remoteSearchHolder.push({
              name: item.name,
              city_id: item.id,
              source_id: item.source_id,
              neighborhoods: [],
            });
          }
          if (
            typeof item.cites === "object" &&
            item !== null &&
            Object.keys(item).length > 0
          ) {
            findMatchingItemsInCities(item.cites, targetData.cities);
          }
        });
      } else if (typeof obj === "object") {
        Object.values(obj).forEach((value) => {
          findMatchingItemsInCities(value, targetData.cities);
        });
      }

      let uniqCategoriesFilters = [] as any;
      targetData.remoteSearchHolder = targetData.remoteSearchHolder.filter(
        (item) => {
          if (
            !uniqCategoriesFilters.includes(item["city_id"]) &&
            targetData.cities.includes(item["city_id"])
          ) {
            uniqCategoriesFilters.push(item["city_id"]);
            return item;
          }
        }
      );
    };

    // یافتن آبجکت دسته بندی
    watch(
      () => targetData.cities,
      () => {
        findMatchingItemsInCities(cities.value, targetData.cities);
      }
    );
    // end filter cities

    // tree-v2
    const getselect = () =>{
      let selected = treeSelected.value.getCheckedNodes()
      targetData.categories = selected.map((item) => (item.id));
      console.log(targetData.categories);
    }

    // وقتی که کاربر شهری را انتخاب میکند ازین طریق فیلتر تعیین محل فعال میشه و کاربر میتونه جستجو را انجام بده
    watch(
      () => getStateCheckedData.value,
      (newValues) => {
        if (newValues.length > 0) {
          const uniqueItems = new Set();
          newValues.forEach((item) => {
            let check = targetData.remoteSearchHolder.filter(
              (data) => data["city_id"] === item.id
            );
            if (check.length === 0) {
              uniqueItems.add(item);
            }
          });

          // اضافه کردن موارد منحصر به فرد به targetData.remoteSearchHolder
          uniqueItems.forEach((uniqueItem) => {
            if (
              typeof uniqueItem === "object" &&
              uniqueItem !== null &&
              "id" in uniqueItem
            ) {
              targetData.remoteSearchHolder.push({
                name: uniqueItem["name"],
                city_id: uniqueItem["id"],
                source_id: uniqueItem["source_id"],
                neighborhoods: [],
              });
            }
          });

          const uniqueId = getStateCheckedData.value.map((item) => {
            return item.id;
          });
          targetData.remoteSearchHolder = targetData.remoteSearchHolder.filter(
            (item) => uniqueId.includes(item["city_id"])
          );
        }
      }
    );

    const checkRequiredCityAndCat = computed(() => {
      if (!types.value) return null;
      return {
        category:
          types.value[targetData.type]?.parameters?.category?.required ?? false,
        city: types.value[targetData.type]?.parameters?.city?.required ?? false,
        neighborhood:
          types.value[targetData.type]?.parameters?.city?.required ?? false,
      };
    });

    // language=HTML
    const notes = ref(`
            # نکات مهم
            <ol class="py-5 fw-light">
                <li class="py-1 border-bottom border-bottom-dashed text-start fs-7 fw-normal">شمارینو بانک شماره آماده
                    نمی باشد و تمام شماره ها به روز بوده و لحظه ای استخراج می شوند.
                </li>
                <li class="py-1 border-bottom border-bottom-dashed text-start fs-7 fw-normal">استخراج با توجه به پلتفرم
                    انتخابی شما ممکن است زمان بر باشد.
                </li>
                <li class="py-1 border-bottom border-bottom-dashed text-start fs-7 fw-normal">پلتفرم دیوار محدودیت
                    استخراج روزانه ( حدودا 200 شماره ) دارد. پس تا رسیدن به سقف محدودیت روزانه، شماره استخراج می گردد و
                    سپس دوباره شمارینو شروع به استخراج می کند.
                </li>
                <li class="py-1 border-bottom border-bottom-dashed text-start fs-7 fw-normal">ممکن است در دسته بندی یا
                    شهر انتخاب شده توسط شما، تعداد آگهی به اندازه تعداد درخواست شماره شما موجود نباشد. بنابراین هر
                    تعدادی که در پلتفرم انتخابی شماره موجود باشد را شمارینو در اختیار شما قرار می دهد.
                </li>
                <li class="py-1 border-bottom border-bottom-dashed text-start fs-7 fw-normal">درصورتی که دوبار پلتفرم
                    دیوار و یا شیپور شما قطع شد یا از شماره دیگری استفاده کنید و یا برای چند روز اتصال را برقرار نکنید.
                </li>
                <li class="py-1 border-bottom border-bottom-dashed text-start fs-7 fw-normal"> درصورت عدم توجه به نکات
                    گفته شده امکان مسدود شدن شماره های شما توسط دیوار ممکن می باشد.
                </li>
            </ol>
        `);

    const submit = () => {
      if (
        checkRequiredCityAndCat.value?.category === true &&
        targetData.categories.length < 1
      ) {
        Swal.fire({
          text: "انتخاب دسته بندی الزامی می باشد.",
          icon: "info",
          buttonsStyling: false,
          confirmButtonText: "بسیار خب",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return false;
      }

      if (
        checkRequiredCityAndCat.value?.city === true &&
        targetData.cities.length < 1
      ) {
        Swal.fire({
          text: "انتخاب شهر الزامی می باشد.",
          icon: "info",
          buttonsStyling: false,
          confirmButtonText: "بسیار خب",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return false;
      }

      if (!formRef.value) return;

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;

          targetData.filters = [
            ...targetData.filters,
            ...targetData.citiesFilters,
            ...targetData.categoriesFilters.filter((item) => {
              if (item["value"] !== "") return item;
            }),
          ];

          ApiService.post("/crawler/request/create", {
            platform : targetData.type,
            numbers_count : targetData.numbers_count,
            categories : targetData.categories,
            // filters : targetData.filters,
            bots : targetData.bots,
          })
            .then(() => {
              loading.value = false;
              emit("refreshList");
              Swal.fire({
                title: "درخواست با موفقیت ثبت شد.",
                html: notes.value,
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "بسیار خب",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                loading.value = false;
                emit("closeModal", true);
              });
            })
            .catch(({ response }) => {
              loading.value = false;
              if (response && response.data)
                store.commit(Mutations.SET_ERROR, response.data);
            });
        } else {
          Swal.fire({
            text: "متاسفانه اطلاعات را به درستی وارد نکرده اید. لطفا بررسی کنید و دوباره ارسال فرمائید.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "بسیار خب",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };

    const checkBots = computed(() => {
      if (bots.value) {
        var countAll = 0;
        let count = bots.value.map((bot) => {
          if (bot.platforms) {
            return (countAll = bot.platforms.filter(
              (item) => item.status === "ready"
            ).length);
          }
        });
        if (count.length === 0) {
          return "not_have_bot";
        } else if (count.length > 0) {
          var countPlat = count.reduce((a, b) => a + b);
          if (countPlat > 0) {
            return true;
          } else {
            return "not_have_active_platform";
          }
        }
      }
      return false;
    });

    const checkPlatformStatusInBot = (botID, plat) => {
      if (!bots.value) return false;
      const bot = bots.value.filter((item) => item.bot_id === botID)[0];
      if (bot)
        return bot.platforms.filter((pl) => pl.platform === plat)[0].status;
      return false;
    };

    const platformBotActiveCount = (plat) => {
      if (bots.value) {
        var countAll = 0;
        let count = bots.value.map((bot) => {
          if (bot.platforms) {
            return (countAll = bot.platforms.filter(
              (item) => item.platform === plat && item.status === "ready"
            ).length);
          }
        });

        return count.reduce((a, b) => a + b);
      }
      return 0;
    };

    onMounted(() => {
      handleFetchData();
    });

    // start neighborhood search
    const neighborhoodList = ref();
    const loadingNeighborhoodList = ref(false);
    let cancelRequestNeighborhoodList;

    async function neighborhoodSearch(searchTerm) {
      if (searchTerm.length < 1) return;
      loadingNeighborhoodList.value = true;
      if (typeof cancelRequestNeighborhoodList === "function") {
        cancelRequestNeighborhoodList();
      }

      try {
        const response = await apiService.query(
          `/neighborhood/${targetData.type}/search?q=${searchTerm}&city=${targetData.activeFilterCityId}`,
          {
            params: { search: searchTerm },
            cancelToken: new axios.CancelToken(function executor(c) {
              cancelRequestNeighborhoodList = c;
            }),
          }
        );
        if (response && response.data) {
          neighborhoodList.value = response.data.data.items;
        }
      } catch (error) {
        //
      } finally {
        loadingNeighborhoodList.value = false;
      }
    }

    // end neighborhood search

    // start search filter
    const searchFilterData = ref();
    const loadingSearchFilterData = ref(false);
    let cancelRequestSearchFilterData;

    async function getSearchFilterData(searchTerm) {
      if (searchTerm.length < 1) return;
      loadingSearchFilterData.value = true;
      if (typeof cancelRequestNeighborhoodList === "function") {
        cancelRequestSearchFilterData();
      }

      try {
        const response = await apiService.query(`/filter/search`, {
          params: { f: targetData.activeFilterSearchId, q: searchTerm },
          cancelToken: new axios.CancelToken(function executor(c) {
            cancelRequestSearchFilterData = c;
          }),
        });
        if (response && response.data) {
          searchFilterData.value = response.data;
        }
      } catch (error) {
        //
      } finally {
        loadingSearchFilterData.value = false;
      }
    }

    // end search filter

    // اگر شهر عوض شد لیست جستجو خالی شود.
    watch(
      () => targetData.activeFilterCityId,
      () => {
        neighborhoodList.value = null;
      }
    );

    const handleNeighborhoodSelected = (item, city_id, source_id) => {
      console.log(item, city_id, source_id);
      let check = targetData.citiesFilters.filter(
        (filter) => filter["city_id"] === city_id && filter["value"] === item.id
      );
      if (check.length === 0) {
        targetData.citiesFilters.push({
          type: "neighborhood",
          city_id: city_id,
          city_source_id: source_id,
          display_name: item.name,
          value: item.id,
        });
      }
    };

    const handleSearchFilterSelected = (item, cat, category_id) => {
      let check = targetData.filters.filter(
        (filter) =>
          filter["type"] === "filter" &&
          filter["id"] === item.id &&
          filter["display_name"] === item.display_name
      );
      if (check.length === 0) {
        targetData.filters.push({
          type: "filter",
          city_id: null,
          category_id: category_id,
          value: item.id,
          filter_id: cat.id,
          display_name: item.name,
          id: item.id,
        });
      }
    };

    // وقتی که کاربر یک محلی را پاک می کند
    watch(
      () => targetData.remoteSearchHolder,
      () => {
        if (Object.keys(targetData.citiesFilters).length > 0) {
          targetData.citiesFilters = targetData.citiesFilters.filter(
            (filter) => {
              let remote = targetData.remoteSearchHolder.find(
                (remote) =>
                  remote["city_id"] === filter["city_id"] &&
                  remote["neighborhoods"].includes(filter["display_name"])
              );
              return !!remote;
            }
          );
        }
      }
    );

    const handleCoordinates = (coordinate) => {
      targetData.filters = targetData.filters.filter(
        (item) => item["type"] !== "coordinates"
      );
      targetData.filters.push({
        type: "coordinates",
        value: coordinate,
        display_name: "جستجوی نقشه",
      });
      console.log("targetData.filters", targetData.filters);
    };

    const customNodeClass = (data: Tree, node: Node) => {
      return null;
    };

    return {
      getselect,

      customNodeClass,
      targetData,
      submit,
      loading,
      formRef,
      rules,
      newTargetModalRef,

      bots,
      categories,
      types,
      filterBotTypes,
      translate,
      getCategories,
      props,
      treeSelected,
      propsState,
      propsCat,
      treeSelectedStates,
      getStateCheckedData,
      cities,
      checkBots,
      checkPlatformStatusInBot,
      platformBotActiveCount,
      neighborhoodSearch,
      handleNeighborhoodSelected,

      crowlerPlatformsList,
      targetPlatform,

      checkRequiredCityAndCat,
      neighborhoodList,
      loadingNeighborhoodList,
      getSearchFilterData,
      searchFilterData,
      loadingSearchFilterData,
      handleSearchFilterSelected,
      handleCoordinates,
      labelPosition,
    };
  },
});
