import { defineStore } from "pinia";
import { computed, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
export const platforms = defineStore("platforminfo", () => {
  const store = useStore();

  // state
  const platformsList = ref();
  const crowlerPlatformsList = ref();
  const tryCounter = ref();
  const targetPlatformID = ref();

  const targetPlatform = computed(() => {
    if (!platformsList.value) return [];
    return platformsList.value[targetPlatformID.value];
  });

  const activePlatforms = computed(() => {
    if (!platformsList.value) return [];
    const values: string[] = Object.values(platformsList.value);
    return values.filter((item) => item["status"] === true);
  });

  const activePlatformsKeys = computed(() => {
    if (!activePlatforms.value) return [];
    return Object.keys(activePlatforms);
  });

  // actions
  const setTargetPlatform = (plat) => {
    targetPlatformID.value = plat;
  };

  const fetchPlatforms = async () => {
    try {
      const { data } = await ApiService.get("platforms");
      platformsList.value = data.data.platforms;
      tryCounter.value = 0;
    } catch (error) {
      if (tryCounter.value < 3) {
        tryCounter.value++;
        await fetchPlatforms();
      }
      if (error.response && error.response.data)
        store.commit(Mutations.SET_ERROR, error.response.data);
    }
  };

  // bank
  const fetchCrowlerPlatforms = async () => {
    await ApiService.query("/crawler/platforms", {})
      .then(({ data }) => {
        crowlerPlatformsList.value = data.data.crawler_platforms;
        // console.log(crowlerPlatformsList.value);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  };

  return {
    targetPlatformID,
    targetPlatform,
    platformsList,
    activePlatforms,
    activePlatformsKeys,

    setTargetPlatform,
    fetchPlatforms,

    // bank
    fetchCrowlerPlatforms,
    crowlerPlatformsList,
  };
});
