import { defineStore } from "pinia";
import { computed, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import JwtService from "@/core/services/JwtService";

export const AllBots = defineStore("allbotsinfo", () => {
  const store = useStore();

  // state
  const botList = ref();
  const crowlerBotList = ref();
  const tryCounter = ref();
  const activeBotId = ref();
  const checkInFetchBotList = ref(false);

  // getter
  const findBot = computed(() => {
    if (!botList.value) {
     return;
    }
    else{
      return botList.value.find((item) => item.bot_id === activeBotId.value);
    }
  });

  const getActiveBotPlatforms = computed(() => {
    if (!findBot.value) return;
    return findBot.value.platforms;
  });

  const findFreeBots = computed(() => {
    if (!botList.value) return [];

    return botList.value.filter((item) => {
      return item?.package?.package?.is_free === "yes";
    });
  });

  const findBotsNoPackage = computed(() => {
    if (!botList.value) return [];

    return botList.value.filter((item) => {
      return item?.package === null;
    });
  });

  // actions
  const setDefaultBotId = async (bot_id) => {
    activeBotId.value = bot_id;
  };

  const findTargetPlatformInBot = (platform) => {
    const plat = findBot.value?.platforms.find(
      (item) => item.platform === platform
    );
    if (plat) return plat;
    return null;
  };

  const powerOff = (bot_id, platform) => {
    ApiService.post(`bot/${bot_id}/power-off`, {
      platform: platform,
    })
      .then(() => {
        // mission accomplished
        // wait to socket response
      })
      .catch(({ response }) => {
        if (response && response.data)
          store.commit(Mutations.SET_ERROR, response.data);
      });
  };

  const adminPowerOff = (bot_id, platform) => {
    ApiService.post(`admin/bot/${bot_id}/${platform}/power-off`,{}
    )
      .then(() => {
        // mission accomplished
        // wait to socket response
      })
      .catch(({ response }) => {
        if (response && response.data)
          store.commit(Mutations.SET_ERROR, response.data);
      });
  };

  const fetchBotList = async (ex_type = 'active') => {
    if (checkInFetchBotList.value === true) return;
    checkInFetchBotList.value = true;
    await ApiService.query("bot/all", {
      params: {
        with_platforms: true,
        with_user: true,
        expiration_type : ex_type,
      },
    })
      .then(({ data }) => {
        botList.value = data.data.bots;
        tryCounter.value = 0;
      })
      .catch(({ response }) => {
        if (tryCounter.value < 3) {
          tryCounter.value++;
          fetchBotList(ex_type);
        }
        if (response && response.data && JwtService.getToken())
          store.commit(Mutations.SET_ERROR, response.data);
      })
      .finally(() => {
        checkInFetchBotList.value = false;
      });
  };

    // bank
  const fetchCrowlerBotList = async () => {
    await ApiService.query("/crawler/bot", {})
        .then(({ data }) => {
          crowlerBotList.value = data.data.crawler_bots.data;
        })
        .catch(({ response }) => {
          if (response && response.data)
            store.commit(Mutations.SET_ERROR, response.data);
        })
  };


  return {
    botList,
    findBot,
    getActiveBotPlatforms,
    findFreeBots,
    setDefaultBotId,
    fetchBotList,
    findTargetPlatformInBot,
    powerOff,
    adminPowerOff,
    findBotsNoPackage,

    // bank
    fetchCrowlerBotList,
    crowlerBotList,

  };
});
